@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    @apply leading-[normal] m-0;
}
*,
::before,
::after {
    border-width: 0;
}
